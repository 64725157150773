<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_division.division')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.division_id"
                        label="text"
                        :options= divisionPreList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_district.district')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.district_id"
                        label="text"
                        :options= districtList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('seedsConfig.organization')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.org_id"
                        label="text"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('seedsConfig.ofcName')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.ofc_name"
                        label="text"
                        :options= zoneOfficeList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                    <b-col cols="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="10"
                        label-for="email"
                        >
                        <b-button type="button" class="float-right" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedsConfig.zoneOfc')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(office_name)="data">
                                        <span class="capitalize">{{ data.item.office_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <span v-if="data.item.status === 1">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                            </span> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { zoneOfficeList, zoneOfficeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                org_id: '',
                division_id: '',
                district_id: '',
                ofc_name: ''
            },
            districtList: [],
            zoneOfficeList: []
        }
    },
    computed: {
        divisionPreList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        orgList: function () {
            const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return tmpData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text }
                }
            })
        },
        // zoneOfficeList: function () {
        //   return this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList.filter(item => item.status === 1)
        // },
        // zoneOfficeList: function () {
        //     if (this.search.ofc_name.value > 0) {
        //     return this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList.filter(item => item.status === 1 && item.org_id === this.search.ofc_name.value)
        //     }
        //     return []
        // },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedsConfig.zoneOfc') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsConfig.zoneOfc') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('org_pro_division.division'), class: 'text-left' },
                { label: this.$t('org_pro_district.district'), class: 'text-left' },
                // { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
                { label: this.$t('seedsConfig.organization'), class: 'text-left' },
                { label: this.$t('seedsConfig.ofccName'), class: 'text-left' },
                // { label: this.$t('seedsConfig.addr'), class: 'text-left' },
                // { label: this.$t('seedsConfig.email'), class: 'text-left' },
                // { label: this.$t('seedsConfig.contact'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'division_name_bn' },
                { key: 'distict_name_bn' },
                // { key: 'upzila_name_bn' },
                { key: 'org_name_bn' },
                { key: 'office_name_bn' },
                // { key: 'address_bn' },
                // { key: 'email' },
                // { key: 'contact_no' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'division_name' },
                { key: 'distict_name' },
                // { key: 'upzila_name' },
                { key: 'org_name' },
                { key: 'office_name' },
                // { key: 'address' },
                // { key: 'email' },
                // { key: 'contact_no' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getPreDistrictList(newVal)
        },
        'search.org_id': function (newVal) {
            this.zoneOfficeList =  this.zoneOfcList(newVal.value)
        }
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, zoneOfficeToggleStatus, item, 'seeds_fertilizer', 'zoneOfficeList')
        },
        loadData () {
            var orgIdSerach = ''
            var divIdSerach = ''
            var disIdSerach = ''
            var ofcIdSerach = ''
            if (this.search.org_id) {
                orgIdSerach = this.search.org_id.value
            }
            if (this.search.district_id) {
                disIdSerach = this.search.district_id.value
            }
            if (this.search.division_id) {
                divIdSerach = this.search.division_id.value
            }
            if (this.search.ofc_name) {
                ofcIdSerach = this.search.ofc_name.value
            }
            const params = Object.assign({}, this.search, { org_id: orgIdSerach, division_id: divIdSerach, district_id: disIdSerach, ofc_name: ofcIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, zoneOfficeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.ListDataShow(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        ListDataShow (data) {
            let tmpData = {}
            let tmpData2 = {}
            let tmpUpzila = {}
            let tmpOrgData = {}
            const listData = data.map(item => {
                tmpData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id && division.status === 0)
                tmpData2 = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id && district.status === 0)
                tmpUpzila = this.$store.state.commonObj.upazilaList.find(upizila => upizila.value === item.upazilla_id && upizila.status === 0)
                tmpOrgData = this.$store.state.commonObj.organizationProfileList.find(orgItem => orgItem.value === item.org_id && orgItem.status === 0)
               const orgData = {
                    division_name: tmpData !== undefined ? tmpData.text_en : '',
                    division_name_bn: tmpData !== undefined ? tmpData.text_bn : '',
                    distict_name: tmpData2 !== undefined ? tmpData2.text_en : '',
                    distict_name_bn: tmpData2 !== undefined ? tmpData2.text_bn : '',
                    upzila_name: tmpUpzila !== undefined ? tmpUpzila.text_en : '',
                    upzila_name_bn: tmpUpzila !== undefined ? tmpUpzila.text_bn : '',
                    org_name: tmpOrgData !== undefined ? tmpOrgData.text : '',
                    org_name_bn: tmpOrgData !== undefined ? tmpOrgData.text_bn : ''
                }
                return Object.assign({}, item, orgData)
            })
            return listData
        },
        getPreDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId.value)
            }

            return districtList
        },
        zoneOfcList (OrgID) {
           return this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList.filter(item => item.status === 1 && item.org_id === OrgID)
        }
    }
}
</script>
